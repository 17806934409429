import { on } from 'delegated-events'
import 'what-input'

// Custom replaceState event
const _wr = function (type) {
  const orig = history[type]

  return function () {
    const rv = orig.apply(this, arguments)
    const e = new Event(type)
    e.arguments = arguments
    window.dispatchEvent(e)
    return rv
  }
}

window.history.replaceState = _wr('replaceState')

// Open modal based on url
const handleModalUrl = (isInitialLoad = false) => {
  const path = window.location.pathname.replace('/', '')

  const targetModal = document.querySelector(`[data-modal="${path}"]`)
  const currentModal = document.querySelector(`[data-modal].is-active`)

  if (targetModal) {
    targetModal.scrollTop = 0
    targetModal.classList.add('is-active')
    document.documentElement.classList.add('js-modal-open')

    if (window.ga) {
      window.ga('send', 'pageview', window.location.pathname)
    }

    if (isInitialLoad) {
      targetModal.classList.add('is-initial')
    }
  }

  if (currentModal) {
    currentModal.classList.remove('is-active', 'is-initial')
    document.documentElement.classList.remove('js-modal-open')
  }
}

window.addEventListener('replaceState', () => handleModalUrl(false))

handleModalUrl(true)

// Handle modal open/close
on('click', '[data-modal-trigger]', (e) => {
  const anchor = e.target.closest('[data-modal-trigger]')

  if (anchor) {
    e.preventDefault()

    window.history.replaceState({}, null, anchor.href)
  }
})

on('click', '.modal-close', () => {
  window.history.replaceState({}, null, '/')
})
